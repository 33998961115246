import React from 'react'
import { Link } from 'react-router-dom'
const Aboutus = () => {
  return (
    <>
    
  <div id="home" className='aboutus'>

  <main>

    <section className="mt-5 mb-5 bg-primary-light gold_pattern pt-5 pb-5 ps-4 pe-4">
      <div className="container mt-5"> 
        <div className="row align-items-center">
        
          <div className="col-lg-7 mb-3 mt-3 order-lg-1">
            <div className="z-index-3">
              <h2 className="main-title text-center text-lg-start mb-4 w-100">Our Mission</h2>
              <p className="text-white fs-18 m-0 text-center text-lg-start">Our mission is to demystify and streamline the world of gold investment, ensuring you maximize the potential of your savings. We're committed to simplifying the gold market, making it more transparent, and providing you with the tools and knowledge needed to make informed investment decisions. With us, you'll experience a hassle-free approach to gold that empowers you to get the most out of your savings."</p>
            </div>
          </div>
          <div className="col-lg-5 mb-3 mt-3 order-lg-2">
            <div className="w-100 text-center">
              <img src="assets/images/about_banner_img.png" alt="about_banner_img" className="mw-100" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="bg-secondary-light px-80">
      <div className="container"> 
        <div className="row">
          <div className="col-lg-12 mb-3 mt-3 order-lg-1">
            <div className="z-index-3">
              <h2 className="main-title text-center mb-4 w-100">Our Vision</h2>
              <p className="text-white fs-18 mb-4 text-center">Our vision is to simplify and bring transparency to the world of gold investment. We're driven by a belief that financial empowerment should be accessible to all. Our mission is to provide you with straightforward, hassle-free ways to make the most of your savings through gold investments. We're committed to demystifying the process, making it as clear as gold itself, and helping you secure your financial future with confidence.</p>
              <p className="text-white fs-18 text-center fw-bold">Join us on this journey toward a simpler, more transparent, and rewarding approach to savings.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="bg-primary-light px-80">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-5">
          <h5 class="h5 text-secondary mb-0">The Team</h5>
            <h2 className="h2 text-primary">Meet our dedicated <br/> Team powering ComTech Gold</h2>
          </div>
          <div className="col-12">
            <div className="team_bg_wrap bg-secondary-light"> 

            
            <div className="d-flex flex-wrap team_wrap">
                <div className="team_thumb">
                  <img src="assets/images/Jignesh.png" />
                </div>
                <div className="team_detail">
                <h2 class="fs-32 mb-0">Jignesh Ved <Link to="https://www.linkedin.com/in/jignesh-ved-b730984"target='blank' class="ml-2"><img src="assets/images/linkend_icon.png" /></Link></h2>
                  <div className="sub_name"><span className="fs-22 text-gradiant">Founder</span>  </div>
                  {/* <p className="fs-12 text-white">Jignesh Ved has a dynamic field experience of 27+ years in project management, consulting, and heading operations in leading firms. He is well experienced in strategic planning to achieve business goals by identifying and prioritizing development initiatives and setting timetables for evaluating, developing, and deploying all web-based services. </p> */}
                  <p class="mb-2">Jignesh Ved has a dynamic field experience of 27+ years in project management, consulting, and heading operations in leading firms. He is well experienced in strategic planning to achieve business goals by identifying and prioritizing development initiatives and setting timetables for evaluating, developing, and deploying all web-based services. </p>
                                      
                  <p><a className="link" data-bs-toggle="modal" data-bs-target="#team2"> Read more </a></p>
                </div>
              </div>

              <div className="d-flex flex-wrap team_wrap">
                <div className="team_thumb">
                  <img src="assets/images/LimSay.jpg" />
                </div>
                <div className="team_detail">
                <h2 className="fs-32 mb-0">Lim Say Cheong <Link to="http://www.linkedin.com/in/lim-say-cheong" target='blank' className="ml-2"><img src="assets/images/linkend_icon.png" /></Link></h2>
                
                  <div className="sub_name"><span className="fs-22 text-gradiant">Independent Director</span>  </div>
                  {/* <p className="fs-12 text-white">Navin is an industry expert with over 20+ years of experience in financial and commodity markets, especially in the Exchange and Banking arena where he has been responsible for enhancing and implementing new processes. He began his career with Deutsche Bank in Global Cash Operations, followed by the National Stock Exchange of India (NSE). During his stint as Director of Operations for Dubai Gold and Commodities Exchange (DGCX), he was instrumental in operationalizing the launch of Shariah Gold Product and the implementation of DMCC Tradeflow warrants for physical Gold Deliveries. </p> */}
                  <p className="mb-2">Lim Say Cheong is an accomplished business leader with over 25 years of experience in executive management and strategic development, with a strong focus on driving business growth and generating revenue. With a proven track record in business development, relationship building, and client acquisition, he has successfully spearheaded growth initiatives across diverse industries and countries. </p>
                                       
                  <p><a className="link" data-bs-toggle="modal" data-bs-target="#team4"> Read more </a></p>
                </div>  
              </div>
            
              <div className="d-flex flex-wrap team_wrap">
                <div className="team_thumb">
                  <img src="assets/images/Ritika.jpeg" />
                </div>
                <div className="team_detail">
                <h2 className="fs-32 mb-0">Ritika Bhardwaj <Link to="https://www.linkedin.com/in/ritika-bhardwaj-3826a413a" target='blank' className="ml-2"><img src="assets/images/linkend_icon.png" /></Link></h2>
                
                  <div className="sub_name"><span className="fs-22 text-gradiant">Head of Operations & Human Resources</span>  </div>
                  {/* <p className="fs-12 text-white">Navin is an industry expert with over 20+ years of experience in financial and commodity markets, especially in the Exchange and Banking arena where he has been responsible for enhancing and implementing new processes. He began his career with Deutsche Bank in Global Cash Operations, followed by the National Stock Exchange of India (NSE). During his stint as Director of Operations for Dubai Gold and Commodities Exchange (DGCX), he was instrumental in operationalizing the launch of Shariah Gold Product and the implementation of DMCC Tradeflow warrants for physical Gold Deliveries. </p> */}
                  <p className="mb-2">Ritika Bhardwaj leads the Operations and Human Resources departments at ComTech Gold, leveraging her extensive expertise to streamline business processes and cultivate a vibrant workplace culture. With a robust background in operational management and HR, Ritika is committed to enhancing efficiency and driving innovation within the company.</p>
                                       
                  <p><a className="link" data-bs-toggle="modal" data-bs-target="#team1"> Read more </a></p>
                </div>
              </div>
              
             

    
              <div className="d-flex flex-wrap team_wrap">
                <div className="team_thumb">
                  <img src="assets/images/Gagan.png" />
                </div>
                <div className="team_detail">
                <h2 class="fs-32 mb-0">Gagandeep Singh <Link to="https://www.linkedin.com/in/gagandeep-sagar-singh" target='blank' className="ml-2"><img src="assets/images/linkend_icon.png" /></Link></h2>
                  <div className="sub_name"><span className="fs-22 text-gradiant">Marketing Specialist</span> </div>
                  {/* <p className="fs-12 text-white">Zulfiqar carries over 25+ years of expertise in driving growth & scaling businesses with a blended experience in Banking, Financial Services, and Technological domains. He has served in management positions at HSBC, Citibank, Thomson Reuters, and NSEIT.</p>
                  <p className="fs-12 text-white">Before joining ComTech, he was heading the sales for India and the Middle East in the Capital Markets segment for NSEIT.</p> */}
                  <p class="mb-2">Gagandeep Singh is a seasoned marketing executive with over a decade of experience. Starting his career at Samsung Service Center, India, he quickly rose to become a Marketing Executive at Crypto & Properties LLC, Dubai. Gagandeep has demonstrated proficiency in using advanced digital marketing tools to enhance brand visibility and lead generation. His tenure at ComTech Gold saw significant marketing successes, including a 25% increase in lead generation and an 80% rise in brand visibility, attributed to his adept use of AI tools.</p>
                                       
                  <p><a className="link" data-bs-toggle="modal" data-bs-target="#team3"> Read more </a></p>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      
    </section>


<div className="modal fade" id="team1" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="team1Label" aria-hidden="true">
<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
  <div className="modal-content bg-primary-light">
    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    <div className="modal-body">
    <div class="team_detail">
                                <h2 class="fs-32 mb-0">Ritika Bhardwaj <Link to="https://www.linkedin.com/in/ritika-bhardwaj-3826a413a" target='blank' className="ml-2"><img src="assets/images/linkend_icon.png" /></Link></h2>
                                <div class="sub_name"><span class="fs-22 text-gradiant">Head of Operations & Human Resources</span> </div>
                                <p class="mt-3">Ritika Bhardwaj leads the Operations and Human Resources departments at ComTech Gold, leveraging her extensive expertise to streamline business processes and cultivate a vibrant workplace culture. With a robust background in operational management and HR, Ritika is committed to enhancing efficiency and driving innovation within the company.</p>
                                <p class="mt-2">Her dedication to excellence and focus on employee development have been instrumental in ComTech Gold's ongoing success and growth. Ritika's international experience in fast-paced, high-growth industries equips her with a deep understanding of Human Resource Development and Personnel Management Practices.</p>
                                <p class="mt-2">In her role, Ritika excels in managing diverse teams, including both permanent and outsourced employees, ensuring seamless communication and coordination across all organizational levels. Before joining ComTech Gold, she held significant HR positions at Tawreeq Holdings Limited and AL Noor Hospitals Group, where she honed her skills in human capital management and administration.</p>
                                <p class="mt-2">Ritika holds a Post Graduate Diploma in Business Management from LBSIMT, India.</p>
                            </div>
    </div>
  </div>
</div>
</div>


<div className="modal fade" id="team2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="team2Label" aria-hidden="true">
<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
  <div className="modal-content bg-primary-light">
    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    <div className="modal-body">
    <div class="team_detail">
                                <h2 class="fs-32 mb-0">Jignesh Ved <Link to="https://www.linkedin.com/in/jignesh-ved-b730984"target='blank' class="ml-2"><img src="assets/images/linkend_icon.png" /></Link></h2>
                                <div class="sub_name"><span class="fs-22 text-gradiant">Founder</span></div>
                                <p class="mt-3">Jignesh Ved has a dynamic field experience of 27+ years in project management, consulting, and heading operations in leading firms. He is well experienced in strategic planning to achieve business goals by identifying and prioritizing development initiatives and setting timetables for evaluating, developing, and deploying all web-based services.</p>
                                <p class="mt-2">Jignesh is now leveraging his experience and connections at ComTech Gold in Ecosystem Development and Educating the community about XDC Network blockchain and tokenization technology benefits.</p>
                            </div>
    </div>
  </div>
</div>
</div>

<div className="modal fade" id="team3" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="team3Label" aria-hidden="true">
<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
  <div className="modal-content bg-primary-light">
    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    <div className="modal-body">
    <div class="team_detail">
                                <h2 class="fs-32 mb-0">Gagandeep Singh <Link to="https://www.linkedin.com/in/gagandeep-sagar-singh" target='blank' className="ml-2"><img src="assets/images/linkend_icon.png" /></Link></h2>
                                <div class="sub_name"><span class="fs-22 text-gradiant">Marketing Specialist</span></div>
                                <p class="mt-3">Gagandeep Singh is a seasoned marketing executive with over a decade of experience. Starting his career at Samsung Service Center, India, he quickly rose to become a Marketing Executive at Crypto & Properties LLC, Dubai. Gagandeep has demonstrated proficiency in using advanced digital marketing tools to enhance brand visibility and lead generation. His tenure at ComTech Gold saw significant marketing successes, including a 25% increase in lead generation and an 80% rise in brand visibility, attributed to his adept use of AI tools.</p>
                                <p class="mt-2">Before ComTech, Gagandeep served in various roles at Alrais Enterprises Group LLC, Hotel Empire Corporate Office, India, and as a Social Media Consultant for prestigious establishments in Dubai. He holds an Economics degree from Chaudhary Charan Singh University, India, and certifications in Content and Digital Marketing. Currently, at ComTech Gold, Gagandeep is leveraging his vast experience to forge strategic partnerships and implement operational efficiencies, aiming to revolutionize the marketing landscape.</p>
                            </div>
    </div>
  </div>
</div>
</div>

<div className="modal fade" id="team4" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="team4Label" aria-hidden="true">
<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
  <div className="modal-content bg-primary-light">
    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    <div className="modal-body">
    <div class="team_detail">
                                <h2 class="fs-32 mb-0">Lim Say Cheong <Link to="http://www.linkedin.com/in/lim-say-cheong" target='blank' className="ml-2"><img src="assets/images/linkend_icon.png" /></Link></h2>
                                <div class="sub_name"><span class="fs-22 text-gradiant">Independent Director</span> </div>
                                <p class="mt-3">Lim Say Cheong is an accomplished business leader with over 25 years of experience in executive management and strategic development, with a strong focus on driving business growth and generating revenue. </p>
                                <p class="mt-2">With a proven track record in business development, relationship building, and client acquisition, he has successfully spearheaded growth initiatives across diverse industries and countries. His expertise includes formulating strategic plans that align with organizational goals while identifying and capitalizing on market opportunities to maximize revenues.</p>
                                <p class="mt-2">As an Independent Director at ComTech Gold, he is well-positioned to leverage his extensive experience to accelerate the company’s growth and aspirations.</p>
                                
                            </div>
    </div>
  </div>
</div>
</div>

  </main>
  
</div>
    </>
  )
}

export default Aboutus